
import { Loading, Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入

import { sleep, startLoading, endLoading, dateFormat,encryptCode,dateStrReplace } from "@/utils/function";
import API from '@/api/teacher/calendar/calendar'
import Tips from "../../../../components/tips.vue";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "Calendar",
    components: {
        Tips
    },
    data() {
        return {
            openDateDialog:false,
            resDate: [
                {
                    date: "2021-01-15",
                    content: [
                        {
                            teacherName: "宿艳",
                            time: "8:30-10:30",
                            name: "测试课程等的巴萨所大所大所",
                            id: 2,
                            checkModel: false,
                        }
                    ],
                },
            ],
            value: new Date(),
            showDivOpen: false,
            is_open: true,
            projectid: [],
            togetherid: [],
            currentMonth: "",
            checkboxArr: [],
            handleSign: "",
            dialogVisible: false,
            dialogMsg: "",
            checkAll: false,
            togetherList: [],
            projectList: [],
            openDateValue: "",
            // 开始时间
            openStartTime: "",
            // 结束时间
            openEndTime: "",
            openWeekNumber: '',
            openWeekArr: [],
            courseList: [],
            openProjectList: [],
            openCourseId: "",
            openTeacherId: "",
            openProjectId: "",
            weekList: [
                { id: 1, name: "周一", check: false },
                { id: 2, name: "周二", check: false },
                { id: 3, name: "周三", check: false },
                { id: 4, name: "周四", check: false },
                { id: 5, name: "周五", check: false },
                { id: 6, name: "周六", check: false },
                { id: 0, name: "周日", check: false },
            ],
            // 修改开放单元id
            open_id: "",
            dialogVisibleCalendar: false, 
            tableData: [
                {
                    date: "2016-05-02",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
            ],
            //   统计数据
            tongjiList: {},
            project_name: '',
            tongjiProjectId: '',
            selectOneDay: '',
            showcheckBox: false,
            //   统计选择时间
            statisticsTime: '',
            showStatistics: false,
            isEdit: false,
            //   点击开放课程按钮后，隐藏开放课程按钮
            isOpenClass: true,
            isEditClass: true,
            isStatistics: true,
            showtongjiInfo: false,
            showDateSelect: false
        };
    },
    created() 
    {
        const self = this;
        var date = new Date();
        self.currentMonth =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
        // 查日历显示数据
        self.getData(self.currentMonth);
        self.getTeacherAndProject();
        self.weekChecked();
    },
    mounted() 
    {
        const self = this;
        /**
         * @name: 点击日历日期，展示月份选择框
         * @author: LXY
         * @date: 2021-01-04 17:58:29
         */
        this.$nextTick(() => {
            let dateBtn = document.querySelector(
                ".el-calendar__title"
            );
            dateBtn.addEventListener("click", () => {
                let self = this
                self.showDateSelect = true
            });
        });
        /**
         * @name: 点击前一个月
         * @author: camellia
         * @date: 2021-01-04 17:58:29
         */
        this.$nextTick(() => {
            let prevBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:nth-child(1)"
            );
            prevBtn.addEventListener("click", () => {
                var date = new Date(this.value);
                // console.log(this.value);
                self.currentMonth = date
                    .toLocaleDateString()
                    .replace(/\//g, "-");
                self.getData(self.currentMonth);
                // 全选取消
                self.checkAll = false;
            });
        });
        /**
         * @name: 点击今天
         * @author: camellia
         * @date: 2021-01-04 17:58:29
         */
        this.$nextTick(() => {
            let prevBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:nth-child(2)"
            );
            prevBtn.addEventListener("click", () => {
                let myDate = new Date();
                let time =
                    myDate.getFullYear() +
                    "-" +
                    Number(myDate.getMonth() + 1) +
                    "-" +
                    myDate.getDate();
                // console.log(time);
                self.getData(time);
                // 全选取消
                self.checkAll = false;
            });
        });
        /**
         * @name: 点击后一个月
         * @author: camellia
         * @date: 2021-01-04 17:58:47
         */
        this.$nextTick(() => {
            let prevBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:last-child"
            );
            prevBtn.addEventListener("click", () => {
                var date = new Date(this.value);
                self.currentMonth = date
                    .toLocaleDateString()
                    .replace(/\//g, "-");
                self.getData(self.currentMonth);
                // 全选取消
                self.checkAll = false;
            });
        });
    },
    methods: 
    {
        changeValue(e) 
        {
            let self = this;
            if (!e) 
            {
                self.showDateSelect = false
                return
            }
            let date = new Date(self.value);
            self.currentMonth = date
                .toLocaleDateString()
                .replace(/\//g, "-");
            self.getData(self.currentMonth);
            // 全选取消
            self.checkAll = false;
        },

        checkOneDay(date) 
        {
            let self = this;
            // 选中的日期
            self.selectOneDay = date
            if (self.resDate) 
            {
                // 遍历日程列表，如果某一天的复选框为选中状态，而且日程的日期等于选中的日期，就添加到选中的日程数组
                self.resDate.forEach((item) => {
                    if (item.datetime == date.day) 
                    {
                        if (item.content && date.checkOneDay) 
                        {
                            item.content.forEach((key) => {
                                self.checkboxArr.push(key.open_id);
                            })
                        }
                        // 如果某一天的复选框为未选中状态，而且日程的日期等于选中的日期，就从选中的日程数组中删除
                        else 
                        {
                            item.content.forEach((key) => {
                                self.checkboxArr.splice(self.checkboxArr.indexOf(key.open_id), 1);
                            })
                        }
                    }
                })
            }
        },

        editOneOpen(projectInfo) 
        {
            let self = this;
            self.open_id = projectInfo.open_id
            self.project_name = projectInfo.name
            self.isEdit = false
            let data = {
                open_id: self.open_id
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.getOpenInfoById(data)
                .then((result) => {
                    if (result.code > 0) 
                    {
                        self.openDateValue = result.openInfo.datetime;
                        self.openStartTime = result.openInfo.startTime;
                        self.openEndTime = result.openInfo.endTime;
                        self.openTeacherId = result.openInfo.classd_uid;
                    }
                    nprogressClose();
                    self.$Loading.hide();
                })
        },

        enterEdit() {
            let self = this
            self.showcheckBox = true;
            self.showDivOpen = true;
            self.is_open = false;
            self.showStatistics = false
            self.openDateValue = '';
            self.openStartTime = '';
            self.openEndTime = '';
            self.openTeacherId = '';
            self.isEdit = true
            self.isEditClass = false
            self.isOpenClass = true
            self.isStatistics = true
        },

        getStatistics() {
            try {
                let self = this;
                let data = {
                    start: self.statisticsTime[0],
                    end: self.statisticsTime[1],
                    project_id: self.tongjiProjectId
                }
                API.getTongjiData(data)
                    .then((res) => {
                        if (res.code > 0) {
                            self.tongjiList = res.data
                            self.showtongjiInfo = true
                            // Message.success(res.msg)

                            // this.$Tips({
                            //     // 消息提示内容
                            //     message:res.msg,
                            //     // 消息提示类型（success-成功,warning-警告/失败）
                            //     messageType:'success',
                            //     displayTime:1500
                            // })
                        }
                        else {
                            this.$Tips({
                                // 消息提示内容
                                message: res.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('网络错误')
            }

        },

        statistics() {
            let self = this;
            self.showStatistics = true
            self.showcheckBox = false
            self.is_open = false
            self.showDivOpen = false
            self.isStatistics = false
            self.isOpenClass = true
            self.isEditClass = true
        },

        closeStatistic() {
            let self = this;
            self.showStatistics = false;
            self.isStatistics = true
        },

        openStudentsList(open_id, open_name, date, time, class_uname) {
            var self = this;
            window.sessionStorage.setItem('havename', 2);
            window.open("#/teacher/studentslist?id=" + open_id + '&hideMenu=' + 1 + '&name=' + open_name + "/" + " " + date + " " + time + " " + class_uname)
        },

        weekChecked() 
        {
            const self = this;
            var now = new Date(); //当前日期
            if (self.openDateValue) {
                now = new Date(self.openDateValue); //当前日期
            }
            var nowDayOfWeek = now.getDay(); //今天本周的第几天 返回0-6，0对应星期天,1-6对应星期一到星期六
            self.openWeekArr = [];
            for (var i = 0; i < self.weekList.length; i++) {
                if (self.weekList[i].id === nowDayOfWeek) {
                    self.weekList[i].check = true;
                    self.openWeekArr.push(self.weekList[i].id);
                }
                /*else if (nowDayOfWeek === 0) 
                {
                    self.weekList[6].check = true;
                    self.openWeekArr.push(self.weekList[i].id);
                } //*/
                else {
                    self.weekList[i].check = false;
                }
            }
        },

        searchProjectList(e)
        {
            const self = this;
            let data = {
                search:e
            };
            API.searchProjectList(data)
                .then(result => {
                    if (result.code > 0) 
                    {
                        console.log(result);
                        self.openProjectList = result.list;
                    }
                })
        },

        courseChange() {
            try 
            {
                const self = this;
                let data = {
                    openCourseId: self.openCourseId
                };
                API.getProjectListByCourseid(data)
                    .then(result => {
                        if (result.code > 0) 
                        {
                            self.openProjectList = result.list;
                        }
                    })
            }
            catch (error) 
            {
                console.log("系统错误!")
            }
        },

        closeOpenDiv() 
        {
            const self = this;
            self.showDivOpen = false;
            self.isOpenClass = true;
            self.isEditClass = true

            // 其他开放单元选项禁用
            for (var item of self.resDate) {
                for (var i = 0; i < item.content.length; i++) {
                    item.content[i].disable = false;
                }
            }
            self.showcheckBox = false
        },

        confirmopen()
        {
            let self = this;
            let data = {
                openCourseId: self.openCourseId,
                openTeacherId: self.openTeacherId,
                openProjectId: self.openProjectId,
                openDateValue: self.openDateValue,
                openStartTime: self.openStartTime,
                openEndTime: self.openEndTime,
                openWeekNumber: self.openWeekNumber ? self.openWeekNumber : 0,
                openWeekArr: self.openWeekArr
            };

            const start = new Date(self.openDateValue+" "+self.openStartTime);
            const end = new Date(self.openDateValue+" "+self.openEndTime);
            
            if (start > end) 
            {
                this.$Tips({
                    // 消息提示内容
                    message: "开课时间错误！",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                });
                return;
            } 

            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.addOpenUnit(data)
                .then(result => {
                    if (result.code > 0) {
                        // 查日历显示数据
                        self.openDateDialog = false;
                        self.getData(self.currentMonth);
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
        },

        execOpenUnit() 
        {
            const self = this;
            self.openStartTime = dateStrReplace(self.openStartTime);
            self.openEndTime = dateStrReplace(self.openEndTime);
            if(self.openStartTime == "" || self.openEndTime == "")
            {
                this.$Tips({
                    // 消息提示内容
                    message: "上课时间格式错误！",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
                return;
            }

            if (self.is_open) 
            {
                if (!self.openTeacherId) 
                {
                    this.$Tips({
                        // 消息提示内容
                        message: "请选择任课教师！",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                } 
                else if (!self.openProjectId) 
                {
                    this.$Tips({
                        // 消息提示内容
                        message: "请选择项目！",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                } 
                else if (!self.openDateValue) 
                {
                    if (self.checkboxArr.length == 0) 
                    {
                        this.$Tips({
                            // 消息提示内容
                            message: "请选择上课日期！",
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            button: [
                                {
                                    type: 1,
                                    text: '知道了'
                                }
                            ]
                        })
                    }
                    else 
                    {
                        return
                    }
                } 
                else if (!self.openStartTime || !self.openEndTime) 
                {
                    this.$Tips({
                        // 消息提示内容
                        message: "请选择上课时间！",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }
                else if (self.openProjectId.length > 1) 
                {
                    self.openDateDialog = true
                    return;
                }
                if (self.openWeekNumber - 0 > 16) 
                {
                    this.$Tips({
                        // 消息提示内容
                        message: "顺延不能大于16周！",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }

                let data = {
                    openCourseId: self.openCourseId,
                    openTeacherId: self.openTeacherId,
                    openProjectId: self.openProjectId,
                    openDateValue: self.openDateValue,
                    openStartTime: self.openStartTime,
                    openEndTime: self.openEndTime,
                    openWeekNumber: self.openWeekNumber ? self.openWeekNumber : 0,
                    openWeekArr: self.openWeekArr
                };
                const start = new Date(self.openDateValue+" "+self.openStartTime);
                const end = new Date(self.openDateValue+" "+self.openEndTime);
                if (start > end) 
                {
                    self.$Tips({
                        // 消息提示内容
                        message: "开课时间错误！",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    });
                    return;
                } 
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.addOpenUnit(data)
                    .then(result => {
                        if (result.code > 0) 
                        {
                            // 查日历显示数据
                            self.getData(self.currentMonth);
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            } 
            else 
            {
                if (!self.openTeacherId) 
                {
                    // Message.warning("请选择任课教师！");
                    self.$Tips({
                        // 消息提示内容
                        message: "请选择任课教师！",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                } 
                else if (!self.openDateValue && self.checkboxArr.length == 0) 
                {
                    // Message.warning("请选择上课日期！");
                    self.$Tips({
                        // 消息提示内容
                        message: "请选择上课日期！",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return
                } 
                else if ((!self.openStartTime || !self.openEndTime) && self.checkboxArr.length == 0) 
                {
                    // Message.warning("请选择上课时间！");
                    self.$Tips({
                        // 消息提示内容
                        message: "请选择上课时间！",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }

                let data = {
                    openTeacherId: self.openTeacherId,
                    openDateValue: self.openDateValue,
                    openStartTime: self.openStartTime,
                    openEndTime: self.openEndTime,
                    open_id: self.checkboxArr.length > 0 ? self.checkboxArr.toString() : self.open_id
                };
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 更新日程信息请求
                API.updateOpenUnit(data)
                    .then((result) => {
                        if (result.code > 0) {
                            // Message.success(result.msg);
                            // 查日历显示数据
                            self.getData(self.currentMonth);
                            self.checkboxArr = [];
                            self.openDateValue = '';
                            self.openStartTime = '';
                            self.openEndTime = '';
                            self.openTeacherId = '';
                            self.isEdit = true;
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else 
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
        },

        checkboxWeekChange(e, param) 
        {
            const self = this;
            if (e) 
            {
                self.openWeekArr.push(param);
            } 
            else 
            {
                let index = self.openWeekArr.indexOf(param);
                self.openWeekArr.splice(index, 1);
            }
        },

        handleCheckAllChange(sign) 
        {
            const self = this;
            self.selectOneDay = ''
            for (let i = 0; i < self.resDate.length; i++) 
            {
                for (let j = 0; j < self.resDate[i].content.length; j++) 
                {
                    self.resDate[i].content[j].checkModel = self.checkAll;
                    self.checkboxArr.push(self.resDate[i].content[j].open_id);
                }
            }
            if (!sign) {
                self.checkboxArr = [];
            }
        },

        handleExec() 
        {
            const self = this;
            if (self.handleSign == "delete") 
            {
                self.handleDelete();
            }
        },

        handleDelete() 
        {
            try 
            {
                const self = this;
                if (self.checkboxArr.length <= 0) 
                {
                    // Message.error("请选择您要删除的开放单元！");
                    self.dialogVisible = false;
                    this.$Tips({
                        // 消息提示内容
                        message: "请选择您要删除的开放单元！",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }
                let data = {
                    id: self.checkboxArr
                };

                // 开启loading
                // startLoading();
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.deleteOpenUnit(data)
                    .then((result) => {
                        if (result.code > 0) 
                        {
                            self.dialogVisible = false;
                            self.getData(self.currentMonth);
                            self.checkboxArr = [];
                            self.checkAll = false;
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
            catch (error) 
            {
                nprogressClose();
                self.$Loading.hide();
            }

        },

        showDialog(str) {
            try 
            {
                const self = this;
                self.handleSign = str;
                if (str == "delete") 
                {
                    self.dialogVisible = true;
                    self.dialogMsg = "删除操作无法撤销，您确定要删除这些开放单元吗？";
                }
                else 
                {
                    // 判断选择框中数据，未选择，不能修改，选择多个，不能修改
                    if (self.checkboxArr.length == 0) 
                    {
                        // Message.warning("请选择您要修改的开放单元！");
                        this.$Tips({
                            // 消息提示内容
                            message: "请选择您要修改的开放单元！",
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            button: [
                                {
                                    type: 1,
                                    text: '知道了'
                                }
                            ]
                        })
                        return;
                    }
                    else if (self.checkboxArr.length > 1) 
                    {
                        // Message.warning("每次只能修改一个开放单元！");
                        this.$Tips({
                            // 消息提示内容
                            message: "每次只能修改一个开放单元！",
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            button: [
                                {
                                    type: 1,
                                    text: '知道了'
                                }
                            ]
                        })
                        return;
                    }
                    self.showDivOpen = true;
                    self.is_open = false;
                    self.open_id = self.checkboxArr[0];

                    // 其他开放单元选项禁用
                    for (var item of self.resDate) 
                    {
                        for (var i = 0; i < item.content.length; i++) 
                        {
                            if (self.open_id != item.content[i].open_id) 
                            {
                                item.content[i].disable = true;
                            }
                        }
                    }
                    let data = {
                        open_id: self.open_id
                    };
                    // 开启loading
                    nprogressStart();
                    self.$Loading.show();
                    // 请求数据
                    API.getOpenInfoById(data)
                        .then((result) => {
                            if (result.code > 0) 
                            {
                                self.openDateValue = result.openInfo.datetime;
                                self.openStartTime = result.openInfo.startTime;
                                self.openEndTime = result.openInfo.endTime;
                                self.openTeacherId = result.openInfo.classd_uid;
                            }
                            // 关闭loading
                            nprogressClose();
                            self.$Loading.hide();
                        })
                }
            }
            catch (error) 
            {
                nprogressClose();
                self.$Loading.hide();
            }
        },
        openDivShow() 
        {
            const self = this;
            self.showDivOpen = true;
            self.is_open = true;
            self.showcheckBox = false;
            self.showStatistics = false;
            self.isEdit = false;
            self.isOpenClass = false;
            self.isEditClass = true;
            self.isStatistics = true;
            self.openDateValue = '';
            self.openStartTime = '';
            self.openEndTime = '';
        },
        checkboxChange(e, param) 
        {
            let self = this;
            self.selectOneDay = ''
            if (e) 
            {
                self.checkboxArr.push(param);
            } 
            else 
            {
                let index = self.checkboxArr.indexOf(param);
                self.checkboxArr.splice(index, 1);
            }
            // ============================================================
            // 判断全选展示
            for (let i = 0; i < self.resDate.length; i++) 
            {
                for (let j = 0; j < self.resDate[i].content.length; j++) 
                {
                    if (self.resDate[i].content[j].open_id == param) 
                    {
                        self.resDate[i].content[j].checkModel = e
                    }
                    if (self.resDate[i].content[j].checkModel !== e) 
                    {
                        self.checkAll = false;
                        return;
                    }
                }
            }
            if (self.checkboxArr.length != 0) 
            {
                self.checkAll = true;
            }
        },

        dealMyDate(param) 
        {
            const self = this;
            let len = this.resDate.length;
            let res = "";
            for (let i = 0; i < len; i++) 
            {
                if (this.resDate[i].datetime == param.day) 
                {
                    this.resDate[i].content.forEach((item) => 
                    {
                        if (item.date == self.selectOneDay.day) 
                        {
                            item.checkModel = self.selectOneDay.checkOneDay
                        }
                    })
                    res = this.resDate[i].content;
                    break;
                }
            }
            return res;
        },

        searchContent() 
        {
            const self = this;
            // 查询列表
            self.getData(self.currentMonth);
        },

        prevMonth(str) 
        {
            var arr = str.split("-"); //将返回[]
            let year = arr[0];
            let month = arr[1];
            if (month == 1) 
            {
                year = year - 1;
                month = 12;
            } 
            else 
            {
                month = month - 1;
            }
            let time;
            if (month != 10 && month != 11 && month != 12) 
            {
                time = year + "-0" + month + "-" + 25;
            } 
            else 
            {
                time = year + "-" + month + "-" + 25;
            }
            return time;
        },

        nextMonth(str) 
        {
            var arr = str.split("-"); //将返回[]
            let year = arr[0];
            let month = arr[1];
            if (month == 12) 
            {
                year = Number(year) + 1;
                month = 1;
            } 
            else 
            {
                month = Number(month) + 1;
            }
            let time;
            if (month != 10 && month != 11 && month != 12) 
            {
                time = year + "-0" + month + "-" + 15;
            } 
            else 
            {
                time = year + "-" + month + "-" + 15;
            }
            return time;
        },

        getData(str) 
        {
            nprogressStart();
            let self = this;
            try {
                let prevTime = self.prevMonth(str);
                let nextTime = self.nextMonth(str);
                let data = {
                    prevTime: prevTime,
                    nextTime: nextTime,
                    togetherid: self.togetherid,
                    projectid: self.projectid
                };
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 发起获取日历显示数据请求
                API.getOpenListByDate(data)
                    .then((result) => {
                        if (result.code > 0) 
                        {
                            self.resDate = result.data;
                        }
                        nprogressClose();
                        // 关闭loading
                        self.$Loading.hide();
                    })
            } 
            catch (error) 
            {
                Message.error("系统错误！");
                nprogressClose();
                self.$Loading.hide();
            }
        },

        getTeacherAndProject() {
            try 
            {
                const self = this;
                let data = {};
                API.getTeacherAndProject(data)
                    .then(result => {
                        if (result.code > 0) 
                        {
                            self.projectList = result.projectList;
                            self.openProjectList = result.projectList;
                            self.togetherList = result.togetherList;
                            self.courseList = result.courseList;
                        }
                    })
            }
            catch (error) 
            {
                Message.error("系统错误！");
                console.log("系统错误！")
            }
        }
    }
};
